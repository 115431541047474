<template lang="html">
  <div class="tw-m-2 tw-rounded-lg tw-border tw-overflow-hidden">
    <button v-for="option in options"
      name="button"
      @click="$emit('changed', option.value)"
      class="tw-py-2 tw-px-4"
      :class="selection.value === option.value ? 'active' : ''"
    >
      {{ option.label }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'SwitchOptions',
  props: {
    options: {
      type: Array,
      default: () => [
        { label: 'Option 1', value: 'option-1' },
        { label: 'Option 2', value: 'option-2' }
      ]
    },
    selection: {
      type: Object,
      default: () => ({
        label: 'Option 1',
        value: 'option-1'
      })
    }
  }
}
</script>

<style lang="css" scoped>
button {
  outline: none;
}
.active {
  background-color: #fd5e0f;
  color: #ffffff;
}
</style>
