<template>
    <div class="">
        <div class="tw-flex tw-flex-row tw-items-center tw-justify-between">
            <h3
                class="tw-flex tw-justify-between tw-text-xl tw-leading-6 tw-font-light tw-text-gray-900 tw-my-6"
            >
                SMS Costs
            </h3>
            <SwitchOptions
                :options="[
                    { label: 'Day', value: 'day' },
                    { label: 'Month', value: 'month' },
                    { label: 'Year', value: 'year' },
                ]"
                :selection="selection"
                @changed="changePeriod"
            />
        </div>

        <div class="">
            <LineChart
                :chartData="chartData"
                :styles="chartStyles"
                :width="500"
                :height="250"
                :options="chartOptions"
            />
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import LineChart from "@/components/Charts/LineChart.vue";
import SwitchOptions from '@/vue-prism/components/input/SwitchOptions.vue'

export default {
    name: 'SMSUsageChart',
    components: {
        LineChart,
        SwitchOptions,
    },
    data() {
      // dataPoints below for testing only
        return {
            dataPoints: [{
              date: '2021-10-11',
              amount: 12,
              currency: 'USD',
              volume: 40,
              volume_sms: 10
            },
            {
              date: '2021-10-11',
              amount: 48,
              currency: 'USD',
              volume: 40,
              volume_sms: 12
            },
            {
              date: '2021-10-11',
              amount: 24,
              currency: 'USD',
              volume: 40,
              volume_sms: 20
            },
            {
              date: '2021-10-11',
              amount: 30,
              currency: 'USD',
              volume: 40,
              volume_sms: 25
            },
            {
              date: '2021-10-11',
              amount: 42,
              currency: 'USD',
              volume: 40,
              volume_sms: 35
            },
            {
              date: '2021-10-11',
              amount: 18,
              currency: 'USD',
              volume: 40,
              volume_sms: 15
            },
            {
              date: '2021-10-11',
              amount: 30,
              currency: 'USD',
              volume: 40,
              volume_sms: 25
            },
            {
              date: '2021-10-11',
              amount: 42,
              currency: 'USD',
              volume: 40,
              volume_sms: 35
            },
            {
              date: '2021-10-11',
              amount: 48,
              currency: 'USD',
              volume: 40,
              volume_sms: 40
            },
            {
              date: '2021-10-11',
              amount: 42,
              currency: 'USD',
              volume: 40,
              volume_sms: 35
            },
          ],
            months: [
                'Jan',
                'Feb',
                'March',
                'Apr',
                'May',
                'June',
                'July',
                'Aug',
                'Sep',
                'Oct',
                'Nov',
                'Dec',
            ],
            period: 'month',
            selection: { label: 'Month', value: 'month' },
        }
    },
    computed: {
        chartData() {
            let labels = [],
                today = new Date();

            switch (this.period) {
                case 'day':
                    this.selection = { label: 'Day', value: 'day' }
                    labels = Array(30)
                        .fill(0)
                        .map((el, i) => {
                          let day = new Date();
                          day.setDate(today.getDate() - (30 - i));
                          return `${day.getDate()} ${this.months[day.getMonth()]} ${day.getFullYear()}`
                        });
                    break
                case 'month':
                    this.selection = { label: 'Month', value: 'month' }
                    labels = Array(12)
                        .fill(0)
                        .map((el, i) => {
                          let day = new Date();
                          day.setMonth(today.getMonth() - (12 - i));
                          return `${this.months[day.getMonth()]} ${day.getFullYear()}`
                        })
                    break
                case 'year':
                    this.selection = { label: 'Year', value: 'year' }
                    labels = Array(6)
                        .fill(0)
                        .map((el, i) => {
                          let day = new Date();
                          day.setFullYear(today.getFullYear() - (6 - i));
                          return day.getFullYear();
                        })
                    break
                default:
                    break
            }

            return {
                labels: labels,
                datasets: [
                    {
                        label: 'SMS Cost',
                        backgroundColor: '#4468c211',
                        borderColor: '#4468c2',
                        borderCapStyle: 'round',
                        data: this.dataPoints.map(item => item.volume_sms),
                    },
                ],
            }
        },
        chartStyles() {
            return {
                position: 'relative',
                maxHeight: '700px',
                maxWidth: '1400px',
            }
        },
        chartOptions() {
            return {
                responsive: true,
                maintainAspectRation: true,
                scales: {

                    xAxes: [{
                      scaleLabel: {
                        display: true,
                        labelString: "Time Period",
                        fontSize: 14
                      },
                    }],
                    yAxes: [{
                        ticks: {
                          labels: ['Segments'],
                          suggestedMin: 0,
                          suggestedMax: 10,
                        },
                        scaleLabel: {
                          display: true,
                          labelString: "SMS Segments",
                          fontSize: 14
                        },
                    }],
                },
                tooltips: {
                  callbacks: {
                    label: (item, { datasets }) => {
                      let cost = "SMS Cost: $" + this.dataPoints[item.index].amount;
                      return cost;
                    }
                  }
                }
            }
        },
    },
    methods: {
        changePeriod(val) {
            this.period = val
        },
    },
}
</script>

<style></style>
