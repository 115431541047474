<template>
    <div class="tw-mt-2 tw-p-2">
        <SMSUsageChart />
    </div>
</template>

<script>
import SMSUsageChart from '@/components/Team/SMSUsageChart.vue'

export default {
    name: 'team-usage',
    components: {
        SMSUsageChart
    }
}
</script>

<style></style>
